import React from 'react';
import AllBlogPosts from '../components/AllBlogPosts';
import IntroBlock from '../components/IntroBlock';
import Layout from '../Layout';
import SEO from '../components/SEO';

const Blog = () => {
    return (<Layout>

        <SEO title="Blog"/>

        <IntroBlock>
            <h1>Blog</h1>
            <p>Keep up to date with TBL news and research here.</p>
        </IntroBlock>

        <AllBlogPosts/>

    </Layout>)
}

export default Blog;